<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import UserAuditReport from './userAudit';

/**
 * Starter page component
 */
export default {
  page: {
    title: "Smart Exchange",
    meta: [{ name: "User Audit Report" }]
  },
  components: { Layout, PageHeader, UserAuditReport },
  data() {
    return {
      title: "User Audit Report",
      items: [
      ]
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-lg-12 ">
            <UserAuditReport/>
        </div>
    </div>
  </Layout>
</template>