<script>
/**
 * UserAudit component
 */

import { reportService } from '../../../../services/report.service';


import {
  paymentServiceMethods,
  notificationMethods
} from "@/state/helpers";

import Timeline from 'timeline-vuejs'

export default {
  components: { Timeline },
  data() {
    return {
      messageWhenNoItems: 'There is no activity',
      transactionData: [],
      variableObject:{},
      timelineItems:[],
      modalTitle:"",
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "transactionReference",
      sortDesc: false, 

      fields: [

        { key: "nationalId", sortable: true, label: " ID" },
        { key: "firstName", sortable: true, label:"First Name" },
        { key: "lastName", sortable: true, label:"Last Name" },
        { key: "email",sortable: true, label: "Email Address" },
        { key: "phoneNumber", sortable: true, label: "Phone Number" },
        { key: "locked", sortable: true, label: "Locked Status" },
        { key: "userType", sortable: true, label: "User Type" },
        { key: "roleId", sortable: true, label: "User Role" },
        { key: "userType", sortable: true, label: "User Type" }, 
        { key: "activityHistory", sortable: false ,label: "User Activity" }, 
      ],

      columns: [
        { field: "nationalId", label: " ID" },
        { field: "firstName",  label:"First Name" },
        { field: "lastName", label:"Last Name" },
        { field: "email", label: "Email Address" },
        { field: "phoneNumber",  label: "Phone Number" },
        { field: "locked",  label: "Locked Status" },
        { field: "userType",  label: "User Type" },
        { field: "roleId", label: "User Role" },
        { field: "userType", label: "User Type" }, 
      ]
    };
  },

  created() {
    this.loadAllUserAuditData()
  },

  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.transactionData.length;
    },

    notification() {
        return this.$store ? this.$store.state.notification : null;
      }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.transactionData.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    ...paymentServiceMethods,
    ...notificationMethods,

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async loadAllUserAuditData() {
      const rowsToBeRemoved = ["-1"];
        try {
          await reportService.getUserAuditResponse().then(response=>{
            if(response.responseBody.length>0){

              rowsToBeRemoved.forEach(element => {
                const indexOfObject = response.responseBody.findIndex(object => {
                  return object.id == element;
                });
                response.responseBody.splice(indexOfObject, 1);
              });
                this.transactionData = response.responseBody;
              }
          });
        } catch (error) {
          console.log(error);
        }
    },

    storeState(object, title){
      this.variableObject = object;
      this.modalTitle = title;
      this.timelineItems = [];
      this.parseTimeline();
    },
    
    
    parseTimeline(){

      this.variableObject.forEach(activity => {
      console.log(activity)
      if(activity.amount== undefined){
        activity.amount = activity.transactionAmount
      }

      if(activity.narration== undefined){
        activity.narration = activity.transactionType
      }

        this.timelineItems.push(
          {
            from: new Date(activity.localDateTime),
            title: activity.transactionType,
            description: "Amount: "+activity.currencyType.toUpperCase()+" "+activity.amount+"<br/>Narration: "+activity.narration+"<br/>Shop: "+activity.shopId
          },
        )
      });
  

    }
  }
};
</script>

<template>
  <div class="card"> 
    <b-modal size="md" id="activityHistory-modal-standard" :title="modalTitle" title-class="font-18" hide-footer> 
   
                <Timeline
                  :timeline-items="timelineItems"
                  :message-when-no-items="messageWhenNoItems"
                  :unique-year="true"
                  :show-day-and-month="false"
                  order="desc"
                />
        
    </b-modal>
    <div class="card-body">
      <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
        <template v-slot:button-content>
          <i class="mdi mdi-dots-vertical"></i>
        </template>
        <!-- item-->
        <b-dropdown-item>
          <vue-excel-xlsx
              class="btn"
              :data="transactionData"
              :columns="columns"
              :file-name="'User Report'"
              :file-type="'xlsx'"
              :sheet-name="'Users'"
              >
              Export Report
          </vue-excel-xlsx>

        </b-dropdown-item>


        <!-- item-->
      </b-dropdown>

      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_filter" class="dataTables_filter text-md-right">
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <div class="table-responsive">
        <b-table
          :items="transactionData"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template v-slot:cell(status)="row">
            <div
              class="badge font-size-14 badge-soft-success"
              :class="{'badge-soft-danger': `${row.item.transactionDetails.transactionStatus}` === 'declined',
              'badge-soft-success': `${row.item.transactionDetails.transactionStatus}` === 'collected',
              'badge-soft-info': `${row.item.transactionDetails.transactionStatus}` === 'approved',
              'badge-soft-warning': `${row.item.transactionDetails.transactionStatus}` === 'pending'}"
            >{{row.item.transactionDetails.transactionStatus==='pending'?'Deposit Pending':row.item.transactionDetails.transactionStatus==='approved'?'Pending Collection':String(row.item.transactionDetails.transactionStatus).toUpperCase()}}</div>
          </template> 
          <template v-slot:cell(fullName)="row">
            {{row.firstName + ' ' + row.lastName}}
          </template>
          <template v-slot:cell(activityHistory)="row">
              <a
                @click="storeState(row.item.activityHistory, 'User Activity')"
                href="javascript:void(0);"
                class="mr-3 text-primary"
                v-b-tooltip.hover
                title="Click to view more">
               <a v-b-modal.activityHistory-modal-standard> <i  class="mdi mdi-eye font-size-18" ></i>View User Activity</a>

              </a>
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>